export default {
  worldWidth: 18,
  worldHeight: 10,
  battleWidth: 12,
  battleHeight: 12,
  availableBiomes: [
    // 'clay',
    'grass_01',
    'grass_02',
    // 'paving_01',
    // 'paving_02',
    // 'sand_01',
    // 'sand_02',
    // 'snow_01',
    // 'snow_02',
    // 'lava_01',
  ],
}
